<script setup>
import { computed } from "vue";
import free from "@/assets/data/free.json";

const currentFree = computed(() => free.filter((item) => item.current));
</script>

<template>
  <section v-if="currentFree.length > 0">
    <h2 class="text-center text-3xl pb-4 uppercase">
      Free Pick{{ currentFree.length > 1 ? "s" : "" }}
    </h2>
    <ul class="flex flex-col items-center md:text-xl space-y-4">
      <li class="flex" v-for="(fr, idx) in currentFree" :key="idx">
        <span class=""> {{ fr.date }} {{ fr.time }}&nbsp;</span>
        <span
          >{{ fr.home }} vs. {{ fr.away }} : {{ fr.pick }} ({{ fr.odd }})</span
        >
      </li>
    </ul>
  </section>
</template>

<script setup>
import { ref, computed } from "vue";
import { Bar, Doughnut } from "vue-chartjs";
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from "chart.js";
import vip from "@/assets/data/vip.json";
import fr from "@/assets/data/free.json";
import ins from "@/assets/data/insiders.json";
import tk from "@/assets/data/tickets.json";
import { useArchiveStore } from "../stores/archive";

ChartJS.register(ArcElement, Title, Tooltip, Legend);

const archiveStore = useArchiveStore();

const ticketArr = archiveStore.getTickets;
const insiderArr = archiveStore.getInsiders;
const vipArr = archiveStore.getVips;
const freeArr = archiveStore.getFreeTips;

console.log(insiderArr);

// const insiderArr = ins.slice(0);
// const vipArr = vip.slice();
// const freeArr = fr.slice();

//broj tipova za koji se racuna statistika
const freeNum = ref(45);
const vipNum = ref(30);
const ticketNum = ref(20);
const insNum = ref(10);

//single bet value
const betValue = ref(100);

const betValueInsider = ref(200);

const currentYear = computed(() => {
  const date = new Date();

  return date.getFullYear();
});
const currentMonth = computed(() => {
  const date = new Date();

  return date.getMonth();
});

const vips = computed(() => vipArr);
const free = computed(() => freeArr);
const tickets = computed(() => ticketArr);
const insiders = computed(() => insiderArr);

// const vipStatistics = computed(() => {
//   return vips.value.map((item) => {
//     const dateArr = item.date.split(".");
//     const dateMonth = +dateArr[1];
//     item.monthStr = months[dateMonth];
//     item.month = dateMonth;

//     item.year = +dateArr[2];

//     return item;
//   });

//   // const result = months.map((item) => {
//   //   let obj = {};
//   //   obj[item] = {};
//   //   return obj;
//   // });
// });

const last30Vip = computed(() => {
  const slicedVip = vips.value.slice(0, vipNum.value);

  const result = {
    winRate: null,
    loseRate: null,
    total: 0,
    win: 0,
    lose: 0,
    void: 0,
    profit: 0,
  };

  slicedVip.forEach((item) => {
    result.total++;

    if (item.ft && item.ft === true && !item.current) {
      result.win++;
      result.profit -= betValue.value;
      result.profit += betValue.value * item.odd;
    } else if (item.ft === false) {
      result.lose++;
      result.profit -= betValue.value;
    } else if (item.ft === "void") {
      result.void++;
    }
  });

  result.winRate = ((result.win / result.total) * 100).toFixed(1);
  result.loseRate = ((result.lose / result.total) * 100).toFixed(1);
  result.VoidRate = ((result.void / result.total) * 100).toFixed(1);

  return result;
});

const last30Free = computed(() => {
  const slicedFree = free.value.slice(0, freeNum.value + 1);

  const result = {
    winRate: null,
    loseRate: null,
    total: 0,
    win: 0,
    lose: 0,
    void: 0,
    profit: 0,
  };

  slicedFree.forEach((item) => {
    result.total++;

    if (item.ft && item.ft === true && !item.current) {
      result.win++;
      result.profit -= betValue.value;
      result.profit += betValue.value * item.odd;
    } else if (item.ft === false) {
      result.lose++;
      result.profit -= betValue.value;
    }
  });

  result.winRate = ((result.win / result.total) * 100).toFixed(1);
  result.loseRate = ((result.lose / result.total) * 100).toFixed(1);

  return result;
});

const last30Tickets = computed(() => {
  const slicedTickets = tickets.value.slice(0, ticketNum.value);

  const result = {
    winRate: null,
    loseRate: null,
    total: 0,
    win: 0,
    lose: 0,
    void: 0,
    profit: 0,
  };

  slicedTickets.forEach((item) => {
    result.total++;
    result.profit -= betValue.value;
    if (item.win === true) {
      result.win++;
      result.profit += betValue.value * item.odd;
    } else if (item.win === false) {
      result.lose++;
    }
  });

  result.winRate = ((result.win / result.total) * 100).toFixed(1);
  result.loseRate = ((result.lose / result.total) * 100).toFixed(1);
  result.VoidRate = ((result.void / result.total) * 100).toFixed(1);

  return result;
});

const last30Insiders = computed(() => {
  const slicedInsiders = insiders.value.slice(0, insNum.value);

  const result = {
    winRate: null,
    loseRate: null,
    total: 0,
    win: 0,
    lose: 0,
    void: 0,
    profit: 0,
  };

  slicedInsiders.forEach((item) => {
    result.total++;

    if (item.ft && item.ft === true) {
      result.win++;
      result.profit -= betValueInsider.value;
      result.profit += betValueInsider.value * item.odd;
    } else if (item.ft === false) {
      result.profit -= betValueInsider.value;
      result.lose++;
    } else if (item.ft === "void") {
      result.void++;
    }
  });

  result.winRate = ((result.win / result.total) * 100).toFixed(1);
  result.loseRate = ((result.lose / result.total) * 100).toFixed(1);
  result.VoidRate = ((result.void / result.total) * 100).toFixed(1);

  return result;
});

const winLoseVipData = {
  labels: [
    `Win ${last30Vip.value.winRate}%`,
    `Lose ${last30Vip.value.loseRate}%`,
  ],
  datasets: [
    {
      data: [last30Vip.value.win, last30Vip.value.lose, last30Vip.value.void],
      backgroundColor: ["#189a80", "#8e8e8e", "#fed4a1"],
    },
  ],
};

const winLoseFreeData = {
  labels: [
    `Win ${last30Free.value.winRate}%`,
    `Lose ${last30Free.value.loseRate}%`,
  ],
  datasets: [
    {
      data: [
        last30Free.value.win,
        last30Free.value.lose,
        last30Free.value.void,
      ],
      backgroundColor: ["#0ea3ab", "#8e8e8e", "#fed4a1"],
    },
  ],
};

const winLoseTicketData = {
  labels: [
    `Win ${last30Tickets.value.winRate}%`,
    `Lose ${last30Tickets.value.loseRate}%`,
  ],
  datasets: [
    {
      data: [
        last30Tickets.value.win,
        last30Tickets.value.lose,
        last30Tickets.value.void,
      ],
      backgroundColor: ["#0ea3ab", "#8e8e8e", "#fed4a1"],
    },
  ],
};

const winLoseInsiderData = {
  labels: [
    `Win ${last30Insiders.value.winRate}%`,
    `Lose ${last30Insiders.value.loseRate}%`,
  ],
  datasets: [
    {
      data: [
        last30Insiders.value.win,
        last30Insiders.value.lose,
        last30Insiders.value.void,
      ],
      backgroundColor: ["#31cf8f", "#8e8e8e", "#fed4a1"],
    },
  ],
};
</script>

<template>
  <base-section class="md:px-4">
    <div class="flex flex-col space-y-24">
      <div
        class="flex flex-col md:flex-row justify-center items-center w-full space-y-28 md:space-y-0"
      >
        <div class="h-48 md:h-64 w-full md:w-1/4">
          <div class="flex flex-col justify-center items-center">
            <h3 class="justify-center">FREE PICKS</h3>
            <p class="text-gray-400 italic text-xs">
              ( last {{ freeNum }} free picks )
            </p>
          </div>

          <Doughnut
            :options="{
              responsive: true,
              maintainAspectRatio: false,
            }"
            :data="winLoseFreeData"
          />
          <div class="flex flex-col py-2 justify-center items-center">
            <p class="justify-center italic">
              Profit
              <span class="text-gray-400 text-xs">( {{ betValue }}€ BET )</span>
            </p>
            <p class="font-bold text-[#0ea3ab]">{{ last30Free.profit }}€</p>
          </div>
        </div>
        <div class="h-48 md:h-64 w-full md:w-1/4">
          <div class="flex flex-col justify-center items-center">
            <h3 class="justify-center">VIP PICKS</h3>
            <p class="text-gray-400 italic text-xs">
              ( last {{ vipNum }} vip picks )
            </p>
          </div>
          <Doughnut
            :options="{
              responsive: true,
              maintainAspectRatio: false,
            }"
            :data="winLoseVipData"
          />

          <div class="flex flex-col py-2 justify-center items-center">
            <p class="justify-center italic">
              Profit
              <span class="text-gray-400 text-xs">( {{ betValue }}€ BET )</span>
            </p>
            <p class="font-bold text-[#189a80]">{{ last30Vip.profit }}€</p>
          </div>
        </div>
        <div class="h-48 md:h-64 w-full md:w-1/4">
          <div class="flex flex-col justify-center items-center">
            <h3 class="justify-center">TICKETS</h3>
            <p class="text-gray-400 italic text-xs">
              ( last {{ ticketNum }} tickets )
            </p>
          </div>
          <Doughnut
            :options="{
              responsive: true,
              maintainAspectRatio: false,
            }"
            :data="winLoseTicketData"
          />
          <div class="flex flex-col py-2 justify-center items-center">
            <p class="justify-center italic">
              Profit
              <span class="text-gray-400 text-xs">( {{ betValue }}€ BET )</span>
            </p>
            <p class="font-bold text-[#0ea3ab]">{{ last30Tickets.profit }}€</p>
          </div>
        </div>
        <div class="h-48 md:h-64 w-full md:w-1/4">
          <div class="flex flex-col justify-center items-center">
            <h3 class="justify-center">INSIDERS</h3>
            <p class="text-gray-400 italic text-xs">
              ( last {{ insNum }} insiders )
            </p>
          </div>
          <Doughnut
            :options="{
              responsive: true,
              maintainAspectRatio: false,
            }"
            :data="winLoseInsiderData"
          />

          <div class="flex flex-col py-2 justify-center items-center">
            <p class="justify-center italic">
              Profit
              <span class="text-gray-400 text-xs"
                >( {{ betValueInsider }}€ BET )</span
              >
            </p>
            <p class="font-bold text-[#31cf8f]">{{ last30Insiders.profit }}€</p>
          </div>
        </div>
      </div>
      <div
        class="flex justify-center items-center w-full py-4 text-xs italic text-center text-gray-500"
      >
        * Subscription costs are not shown in profit. Deduct subscription costs
        to calculate your actual profit.
      </div>
    </div>
  </base-section>
</template>

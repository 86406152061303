<template>
  <ul :key="insiders" class="flex flex-wrap">
    <ticket-item
      v-for="(tk, idx) in tickets"
      :key="idx"
      :data="tk"
      :index="idx + 1"
    ></ticket-item>
  </ul>
</template>

<script setup>
import TicketItem from "@/components/TicketItem.vue";
import { useArchiveStore } from "@/stores/archive";
import { useConfigStore } from "@/stores/config";
import { computed, ref } from "vue";
import tk from "@/assets/data/tickets.json";

const archiveStore = useArchiveStore();
const configStore = useConfigStore();

// const insiders = computed(()=> archiveStore.getInsiders)
// const tickets = computed(() =>
//   tk.reverse().slice(tk.length > 10 ? tk.length - 10 : 0)
// ).filter((item) => item.ft === true);

const tickets = computed(() =>
  tk
    .slice()
    .reverse()
    .slice(tk.length > 12 ? tk.length - 12 : 0)
);

const currentTickets = computed(() => archiveStore.getCurrentTickets);
</script>

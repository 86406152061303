<template>
  <div
    @click="handleImgClick"
    class="image relative w-full inline-block align-middle overflow-hidden"
    :class="props.link ? 'cursor-pointer' : ''"
    :style="{
      width: props.width + '!important',
      height: props.height + '!important',
    }"
  >
    <div
      v-if="!!isLoading && !error"
      class="inset-0 absolute flex justify-center items-center"
    >
      <base-spinner :size="props.spinnerSize" :color="props.spinnerColor">
      </base-spinner>
    </div>

    <div
      v-if="!!error"
      class="inset-0 absolute flex justify-center items-center"
    ></div>

    <div :style="imageRatioStyle"></div>
    <div class="inset-0 absolute w-full h-full">
      <img
        :style="[imgStyle, computedStyle]"
        :class="props.imgClass + ' ' + computedClass"
        @load="handleLoad"
        @error="handleError"
        :loading="props.loading"
        :fetchpriority="props.fetchpriority"
        :src="getImgSrc(props.src)"
        :srcset="props.srcset"
        :sizes="props.sizes"
        :alt="isLoading ? '' : props.alt"
        class="w-full h-full border-none"
      />
    </div>
  </div>
</template>
<script setup>
import { ref, computed, watch } from "vue";
import { useRouter } from "vue-router";

const props = defineProps({
  src: {
    //src atr of image
    type: String,
    required: true,
  },
  srcset: {
    type: String,
  },
  sizes: {
    type: String,
  },
  width: String,
  height: String,
  ratio: {
    type: [String, Number],
  },
  loading: {
    // used for native browser lazy loading
    type: String,
    default: "lazy",
    validator: (val) => ["lazy", "eager"].includes(val),
  },
  fetchpriority: {
    // used for native browser fetch priority
    type: String,
    default: "auto",
    validator: (val) => ["auto", "high", "low"].includes(val),
  },
  alt: {
    // alt atr of image
    type: String,
  },
  imgClass: {
    // class for image HTML element
    type: String,
    default: "",
  },
  imgStyle: {
    // style for image HTML element
    type: String,
    default: "",
  },
  noTransition: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: Boolean,
  },
  spinnerColor: {
    type: String,
  },
  spinnerSize: {
    type: String,
    default: "3em",
  },
  fit: {
    type: String,
    default: "cover",
  },
  position: {
    type: String,
    default: "50% 50%",
  },
  link: {
    type: [Object, null],
    default: null, // no link
  },
});

const router = useRouter();

const isLoading = ref(true);
const error = ref(false);

const emits = defineEmits(["load", "error"]);

//Emmited then image has been loaded by the browser
const handleLoad = function () {
  //paylod -> URL of image that has been loaded
  isLoading.value = false;
  emits("load", props.src);
};

//Emmited when browser could not load image
const handleError = function () {
  error.value = true;
  isLoading.value = false;
  emits("error", new Error("Greška pri učitavanju"));
};

const handleImgClick = function () {
  if (props.link) {
    // if image should behave as link
    router.push({ name: props.link.name, params: props.link.params });
  }

  return;
};

const computedClass = computed(() => {
  return (
    (props.noTransition ? "" : "image--transition") +
    (isLoading.value ? " opacity-0" : " opacity-100")
  );
});

// const computedStyle = computed(()=>{
//     return {objectFit:props.fit,
//             objectPosition:props.position}
// })
const computedStyle = computed(() => {
  return {};
});

const getImgSrc = function (src) {
  if (props.src.startsWith("~assets")) {
    try {
      let path = require("@/assets" + src.slice(7));
      return path;
    } catch (err) {
      // no need to handle this, because handleError handler will catch it when browser fire @error event
    }
  }

  return src;
};

//used to determine padding to force image ratio
const imageRatioStyle = computed(() => {
  let ratio;

  if (typeof props.ratio === "string") {
    const nums = props.ratio.split("/");
    ratio = Number(nums[0] / nums[1]);
  } else {
    ratio = Number(props.ratio);
  }

  return isNaN(ratio) !== true && ratio > 0
    ? { paddingBottom: `${100 / ratio}%` }
    : null;
});
</script>

<style scoped>
.image--transition {
  transition: opacity 0.25s ease-in;
}
</style>
